<template>
  <v-form
    :lazy-validation="true"
    @submit.prevent="handleSubmit"
    v-model="valid"
  >
    <v-card>
      <v-toolbar color="primary" dark flat>
        <v-toolbar-title>{{ $t("common.edit") }}</v-toolbar-title>
        <div class="flex-grow-1"></div>
      </v-toolbar>
      <v-card-text>
        <v-text-field
          :label="$t('application.fields.name')"
          name="name"
          prepend-icon="mdi-domain"
          type="text"
          v-model="appName"
          :rules="nameRules"
        ></v-text-field>
      </v-card-text>

      <v-card-text>
        <company-selector v-model="sharedCompanies" />
      </v-card-text>

      <v-card-actions>
        <v-btn color="primary" type="submit" x-large>{{
          $t("common.update")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { mapActions, mapState } from "vuex";
import CompanySelector from "../company/CompanySelector.vue";

export default {
  name: "ApplicationEdit",
  components: { CompanySelector },
  data() {
    return {
      submitted: false,
      valid: false,
      appName: "",
      id: null,
      sharedCompanies: [],
      nameRules: [
        (v) => !!v || this.$t("application.validation.nameRequired"),
        (v) =>
          (v && v.length <= 255) || this.$t("application.validation.nameMax"),
      ],
    };
  },
  computed: {
    ...mapState("users", [
      "status",
      "currentUser",
      "userCompany",
      "hasCompany",
    ]),
    ...mapState("applications", { application: "currentApplication" }),
  },
  methods: {
    ...mapActions("applications", {
      updateApplication: "update",
      getApplication: "getApplication",
    }),
    async handleSubmit() {
      this.submitted = true;

      if (this.appName && this.id !== null)
        await this.updateApplication({
          id: this.id,
          applicationPayload: {
            name: this.appName,
            sharedCompanies: this.sharedCompanies,
          },
        });

      this.$router.push("/application");
    },
  },

  async created() {
    if (
      this.application !== undefined &&
      this.application.applicationId !== this.$route.params.id
    ) {
      await this.getApplication(this.$route.params.id);
      this.appName = this.application.name;
      this.id = this.application.applicationId;

      if (
        this.application.sharedCompanies != undefined &&
        this.application.sharedCompanies.length > 0
      )
        this.sharedCompanies = this.application.sharedCompanies.map(
          (d) => d.companyId
        );
    }
  },
};
</script>

<style></style>
