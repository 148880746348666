<template>
  <v-container fluid v-if="permitted('SuperAdmin')">
    <v-row>
      <span class="text-h6 mb-4">{{ $t("company.shareApp") }}</span>
    </v-row>
    <v-row>
      <v-chip
        cols="2"
        v-for="company in filteredCompanies"
        :key="company.companyId"
        class="d-flex align-center justify-center company-chip"
      >
        <v-checkbox v-model="localSelection" :value="company.companyId" /><span
          class="subtitle"
          >{{ company.name }}</span
        >
      </v-chip>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
export default {
  name: "CompanySelector",
  props: {
    propsCompanies: {
      type: Array || null,
      default: () => [],
    },
  },

  model: {
    prop: "propsCompanies",
  },

  data() {
    return {};
  },

  methods: {
    ...mapActions("companies", ["getAll"]),
  },

  computed: {
    ...mapState("companies", ["companies"]),
    ...mapGetters("users", { userCompanyId: "companyId" }),

    filteredCompanies() {
      return this.companies.filter((d) => d.companyId != this.userCompanyId);
    },

    localSelection: {
      get() {
        return this.propsCompanies;
      },

      set(val) {
        this.$emit("input", val);
      },
    },
  },

  async created() {
    await this.getAll();
  },
};
</script>

<style>
.company-chip {
  border: 1px solid black;
  border-radius: 10px;
  margin: 0.4rem;
}
</style>